<template>
  <div class="app-context expanded">
    <app-header :headerOption="headerOption" ref="appheader"></app-header>
    <div class="context modify">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join document">
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">수신병원</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.receive.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료의사</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="form.doctor.value"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단코드</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진단코드를 입력해주세요."
              v-model="form.code.value"
              @keyup="checkCode()"
              @blur="checkCode()"
              maxlength="6"
            />
            <p class="warn-message" v-if="form.code.hasError">
              {{ form.code.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료과목</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진료과목을 입력해주세요."
              v-model="form.subject.value"
              @keyup="checkSubject()"
              @blur="checkSubject()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.subject.hasError">
              {{ form.subject.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">환자명</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진료과목을 입력해주세요."
              v-model="form.name.value"
              @keyup="checkName()"
              @blur="checkName()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.name.hasError">
              {{ form.name.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">생년월일</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              v-model="form.birth.value"
              @keyup="checkBirth()"
              @blur="checkBirth()"
              maxlength="8"
            />
            <p class="warn-message" v-if="form.birth.hasError">
              {{ form.birth.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">성별</h5>
          </div>
          <div class="radio-input-wrap">
            <div class="item">
              <input
                type="radio"
                id="male"
                v-model="form.gender.value"
                value="male"
                @click="checkgender(form.gender.value)"
              />
              <label for="male">남성</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="female"
                v-model="form.gender.value"
                value="female"
                @click="checkgender(form.gender.value)"
              />
              <label for="female">여성</label>
            </div>
            <p class="warn-message" v-if="form.gender.hasError">
              {{ form.gender.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">연락처</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="휴대폰 번호를 입력해주세요."
              v-model="form.phone.value"
              @keyup="checkPhone()"
              @blur="checkPhone()"
              maxlength="11"
            />
            <p class="warn-message" v-if="form.phone.hasError">
              {{ form.phone.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">개인정보 동의요청</h5>
          </div>
          <div class="agreement-check">
            <div class="inline-btn-wrap" :class="{ active: form.phone.flag }">
              <button type="button" @click="callAgreementRequestApi()">
                동의요청
              </button>
            </div>
            <p
              class="warn-message"
              v-if="
                form.isPressAgreementRequest.flag === false && form.phone.flag
              "
            >
              환자에게 개인정보 동의요청을 전송해주세요
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">개인정보 동의여부</h5>
          </div>
          <div class="agreement-check">
            <div class="check-btn">
              <img
                src="@/assets/images/onck.svg"
                v-if="form.isCheckedAgreement.flag"
              />
              <img src="@/assets/images/unck.svg" v-else />
            </div>
            <div
              class="inline-btn-wrap"
              :class="{ active: form.isPressAgreementRequest.flag }"
            >
              <button type="button" @click="callAgreementCheckedApi()">
                동의확인
              </button>
            </div>
            <p class="warn-message" v-if="form.isCheckedAgreement.hasError">
              {{ form.isCheckedAgreement.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">되의뢰여부</h5>
          </div>
          <div class="radio-input-wrap">
            <div class="item">
              <input
                type="radio"
                id="replyTrue"
                v-model="view.replyRadio"
                value="rTrue"
                :disabled="view.replyRadio !== ''"
              />
              <label for="replyTrue">되의뢰</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="replyFalse"
                v-model="view.replyRadio"
                value="rFalse"
                :disabled="view.replyRadio !== ''"
              />
              <label for="replyFalse">일반진료</label>
            </div>
            <p class="warn-message" v-if="form.gender.hasError">
              {{ form.gender.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단명 또는 주증상</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="진단명 또는 주증상을 입력해주세요."
              v-model="form.diagnosis.value"
              @keyup="checkDiagnosis()"
              @blur="checkDiagnosis()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.diagnosis.hasError">
              {{ form.diagnosis.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">환자상태 또는 의뢰내용</h5>
          </div>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="환자상태 또는 의뢰내용을 입력해주세요."
              v-model="form.vital.value"
              @keyup="checkVital()"
              @blur="checkVital()"
              maxlength="40"
            />
            <p class="warn-message" v-if="form.vital.hasError">
              {{ form.vital.errorTxt }}
            </p>
          </div>
        </div>
        <div class="j-article last">
          <div class="title-block">
            <h5 class="title">첨부파일</h5>
          </div>
          <div class="attachment-wrap activate">
            <div class="attachment">
              <div class="a-input-wrap">
                <span
                  class="file-name"
                  v-text="form.attachment.filename"
                ></span>
                <input
                  class="hidden file-upload"
                  type="file"
                  @change="onFileChange($event)"
                />
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="attachmentFile()">
                  파일첨부
                </button>
              </div>
              <div
                class="a-btn-wrap delete"
                v-if="form.attachment.filename !== ''"
              >
                <button type="button" @click="deleteFile()">삭제</button>
              </div>
            </div>
          </div>
        </div>
        <div class="store-btn-wrap">
          <button type="button" class="prev" @click="goPrev()">이전으로</button>
          <button
            type="button"
            :class="{
              active:
                form.diagnosis.flag &&
                form.vital.flag &&
                form.subject.flag &&
                form.code.flag &&
                form.name.flag &&
                form.birth.flag &&
                form.gender.flag &&
                form.phone.flag &&
                form.isCheckedAgreement.flag,
            }"
            @click="next()"
          >
            등록완료
          </button>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    AppHeader,
    AppFooter,
    Directive,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "진료의뢰서 등록",
        isTitleNewLine: false,
        content:
          "진료의뢰서 등록에 필수적인 내용입니다.\n저장 후 등록 완료됩니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      form: {
        userData: {
          drId: "",
          drNm: "",
          hospNm: "",
        },
        diagnosis: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단명을 입력해주세요.",
        },
        vital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*환자상태를 입력해주세요.",
        },
        receive: {
          value: "",
        },
        doctor: {
          value: "",
        },
        subject: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료과목을 입력해주세요.",
        },
        code: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진단코드를 입력해주세요.",
        },
        name: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "환자명을 입력해주세요.",
        },
        birth: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "생년월일을 입력해주세요.",
        },
        gender: {
          value: null,
          hanValue: "",
          flag: false,
          hasError: false,
          errorTxt: "성별을 선택해주세요.",
        },
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*8~20자, 영문+숫자 조합으로 입력해주세요",
        },
        attachment: {
          flag: false,
          title: "병원 로고",
          source: "",
          url: "",
          filename: "",
        },
        isPressAgreementRequest: {
          flag: false,
          hasError: false,
          errorTxt: "*동의요청을 확인해주세요.",
        },
        isCheckedAgreement: {
          flag: false,
          hasError: false,
          errorTxt: "*동의여부를 확인해주세요.",
        },
        agreeId: {
          value: "",
        },
        rcvDrId: {
          value: "",
        },
        referId: {
          value: "",
        },
      },
      view: {
        replyRadio: "rTrue",
      },
      popupSet: {},
    };
  },
  created() {
    this.bringBasic();
    this.getReplyStatus();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("request", [
      "GET_SMS_LIST",
      "GET_AGREE_RESULT",
      "GET_REPLY_STATUS",
    ]),
    ...mapGetters("join", ["GET_BASIC"]),
    genetateHyphen() {
      return (numData, isBirth) => {
        /*console.log("가져온 번호들", numData);
        console.log("팩스인가?", isBirth);*/
        const number = numData.toString().replace(/[^0-9]/g, "");
        let generatedNum = "";
        if (isBirth) {
          if (number.length < 5) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 4);
            generatedNum += "-";
            generatedNum += number.substr(4, 2);
            generatedNum += "-";
            generatedNum += number.substr(6);
          }
          this.form.birth.value = generatedNum;
        } else {
          if (number.length < 4) {
            return number;
          }
          if (number.length < 7) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3);
          } else if (number.length < 11) {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 3);
            generatedNum += "-";
            generatedNum += number.substr(6);
          } else {
            generatedNum += number.substr(0, 3);
            generatedNum += "-";
            generatedNum += number.substr(3, 4);
            generatedNum += "-";
            generatedNum += number.substr(7);
          }
          this.form.phone.value = generatedNum;
        }
        return generatedNum;
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("request", ["SET_REQUEST"]),
    ...mapActions("request", [
      "BRING_SMS_CODE",
      "BRING_AGREE_RESULT",
      "REFER_REPLY",
    ]),
    ...mapActions("join", ["FETCH_BASIC"]),
    deleteFile() {
      this.form.attachment.flag = false;
      this.form.attachment.source = null;
      this.form.attachment.url = "";
      this.form.attachment.filename = "";
      document.querySelector("input.file-upload").value = "";
    },
    goPrev() {
      this.$router.push("/v/mypage/request/0").catch(()=>{});
    },
    getReplyStatus() {
      if (this.GET_REPLY_STATUS.referId === undefined) {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/v/mypage/request/0";
      }
      this.form.receive.value = this.GET_REPLY_STATUS.receive;
      this.form.doctor.value = this.GET_REPLY_STATUS.doctor;
      this.form.rcvDrId.value = this.GET_REPLY_STATUS.drId;
      this.form.referId.value = this.GET_REPLY_STATUS.referId;
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      //console.log("가져올 파일명: ", getFile[0].name);
      //console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment.source = event.target.files[0];
      /** getFile */
      this.form.attachment.url = getFileUrl;
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.flag = true;
      /*console.log("flag", this.form.attachment.flag);
      console.log("파일", event.target.files[0]);
      console.log("소스", this.form.attachment.source);*/
    },
    attachmentFile() {
      const parentNode = document.querySelector(".j-article.last");
      //console.log("parentNode", parentNode);
      parentNode.querySelector("input.file-upload").click();
    },
    async bringBasic() {
      try {
        await this.FETCH_BASIC().then(() => {
          (this.form.userData.drId = this.GET_BASIC.drId),
            (this.form.userData.drNm = this.GET_BASIC.drNm),
            (this.form.userData.hospNm = this.GET_BASIC.hospNm);
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "기본정보를 조회하는데 실패하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async callAgreementCheckedApi() {
      try {
        await this.BRING_AGREE_RESULT(this.form.agreeId.value).then(() => {
          //console.log("가져온 동의번호 결과", this.GET_AGREE_RESULT);
          if (this.GET_AGREE_RESULT) {
            this.form.isCheckedAgreement.flag = true;
            this.form.isCheckedAgreement.hasError = false;
            this.popupSet.content = "동의여부가 확인되었습니다.";
          } else {
            this.form.isCheckedAgreement.flag = false;
            this.form.isCheckedAgreement.hasError = true;

            this.form.isCheckedAgreement.errorTxt =
              "동의요청이 서버에 전송되지 않아 동의여부를 확인할 수 없습니다.";
            this.popupSet.content =
              "동의요청이 서버에 전송되지 않아 동의여부를 확인할 수 없습니다.";
          }
          this.SET_POPUP(true);
          this.popupSet.title = "동의요청 확인";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        });
      } catch (error) {
        console.log("error:", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 동의여부를 확인하지 못하였습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async callAgreementRequestApi() {
      /** APi 액션 후 ->  isPressAgreementRequest*/
      if (this.form.phone.flag) {
        try {
          const payload = {
            hpNo: this.form.phone.value,
          };
          await this.BRING_SMS_CODE(payload).then(() => {
            //console.log("SMS-LIST-RESULT", this.GET_SMS_LIST);
            this.form.isPressAgreementRequest.flag = true;
            this.form.agreeId.value = this.GET_SMS_LIST.agreeId;
            this.SET_POPUP(true);
            this.popupSet.title = "동의요청";
            this.popupSet.content = "동의요청을 성공적으로 발송하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          });
        } catch (error) {
          console.log("error", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 동의요청을 전송하지 못하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else {
        this.form.phone.hasError = true;
        this.form.phone.errorTxt =
          "연락처를 기입한 후 동의요청을 수행해주세요.";
      }
    },
    checkVital() {
      this.form.vital.flag = false;
      this.form.vital.hasError = true;
      const Vital = this.form.vital.value.replace(/\s/gi, "");
      if (Vital === "") {
        this.form.vital.flag = false;
        this.form.vital.errorTxt = "*환자상태를 입력해주세요.";
        return false;
      }
      const isVital = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isVital.test(Vital)) {
        this.form.vital.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.vital.flag = false;
        return false;
      }
      this.form.vital.flag = true;
      this.form.vital.hasError = false;
      this.form.vital.errorTxt = "";
      return true;
    },
    checkDiagnosis() {
      this.form.diagnosis.flag = false;
      this.form.diagnosis.hasError = true;
      const Diagnosis = this.form.diagnosis.value.replace(/\s/gi, "");
      if (Diagnosis === "") {
        this.form.diagnosis.flag = false;
        this.form.diagnosis.errorTxt = "*진단명을 입력해주세요.";
        return false;
      }
      const isDiagnosis = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isDiagnosis.test(Diagnosis)) {
        this.form.diagnosis.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.diagnosis.flag = false;
        return false;
      }
      this.form.diagnosis.flag = true;
      this.form.diagnosis.hasError = false;
      this.form.diagnosis.errorTxt = "";
      return true;
    },
    checkPhone() {
      this.form.phone.flag = false;
      this.form.phone.hasError = true;
      const phone = this.form.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        this.form.phone.errorTxt = "*핸드폰번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9]{8,11}$/;
      if (!isPhone.test(phone)) {
        this.form.phone.errorTxt = "*-을 제외하고 숫자만 입력해주세요.";
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        return false;
      }
      this.form.phone.flag = true;
      this.form.phone.hasError = false;
      this.form.phone.errorTxt = "";
      this.form.isPressAgreementRequest.flag = false;
      return true;
    },

    checkName() {
      this.form.name.flag = false;
      this.form.name.hasError = true;
      const Name = this.form.name.value.replace(/\s/gi, "");
      if (Name === "") {
        this.form.name.flag = false;
        this.form.name.errorTxt = "*이름을 입력해주세요.";
        return false;
      }
      const isName = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isName.test(Name)) {
        this.form.name.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.name.flag = false;
        return false;
      }
      this.form.name.flag = true;
      this.form.name.hasError = false;
      this.form.name.errorTxt = "";
      return true;
    },
    checkSubject() {
      this.form.subject.flag = false;
      this.form.subject.hasError = true;
      const Subject = this.form.subject.value.replace(/\s/gi, "");
      if (Subject === "") {
        this.form.subject.flag = false;
        this.form.subject.errorTxt = "*진료과목을 입력해주세요.";
        return false;
      }
      const isSubject = /^[가-힣|a-z|A-Z]{2,40}$/;
      if (!isSubject.test(Subject)) {
        this.form.subject.errorTxt = "*한글 및 영문만 입력할 수 있습니다.";
        this.form.subject.flag = false;
        return false;
      }
      this.form.subject.flag = true;
      this.form.subject.hasError = false;
      this.form.subject.errorTxt = "";
      return true;
    },
    checkCode() {
      this.form.code.flag = false;
      this.form.code.hasError = true;
      const Code = this.form.code.value.replace(/\s/gi, "");
      if (Code === "") {
        this.form.code.flag = false;
        this.form.code.errorTxt = "*진단코드를 입력해주세요.";
        return false;
      }
      const isCode = /^[0-9]{4,6}$/;
      if (!isCode.test(Code)) {
        this.form.code.errorTxt = "*-을 제외한 숫자만 입력할 수 있습니다.";
        this.form.code.flag = false;
        return false;
      }
      this.form.code.flag = true;
      this.form.code.hasError = false;
      this.form.code.errorTxt = "";
      return true;
    },
    checkBirth() {
      this.form.birth.flag = false;
      this.form.birth.hasError = true;
      const birth = this.form.birth.value.replace(/\s/gi, "");
      if (birth === "") {
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        this.form.birth.errorTxt = "*생년월일을 입력해주세요.";
        return false;
      }
      const isBirth = /^[0-9]{8}$/;
      if (!isBirth.test(birth)) {
        this.form.birth.errorTxt =
          "*YYYY-MM-DD 형식에 맞추어 숫자만 입력해주세요.";
        this.form.birth.flag = false;
        this.form.birth.hasError = true;
        return false;
      }
      this.form.birth.flag = true;
      this.form.birth.hasError = false;
      this.form.birth.errorTxt = "";
      return true;
    },
    checkgender(data) {
      if (data === "male") {
        this.form.gender.hanValue = "남성";
      } else {
        this.form.gender.hanValue = "여성";
      }
      this.form.gender.flag = true;
      this.form.gender.hasError = false;
    },
    initFlag() {},
    checkFlag() {
      if (
        !this.form.diagnosis.flag ||
        !this.form.vital.flag ||
        !this.form.subject.flag ||
        !this.form.code.flag ||
        !this.form.name.flag ||
        !this.form.birth.flag ||
        !this.form.gender.flag ||
        !this.form.phone.flag ||
        !this.form.isCheckedAgreement.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (this.form.userData.drId === "") {
          //console.log("기본정보 못받아오는 에러");
        }
        if (!this.form.diagnosis.flag) {
          this.form.diagnosis.hasError = true;
        }
        if (!this.form.vital.flag) {
          this.form.vital.hasError = true;
        }
        if (!this.form.subject.flag) {
          this.form.subject.hasError = true;
        }
        if (!this.form.code.flag) {
          this.form.code.hasError = true;
        }
        if (!this.form.name.flag) {
          this.form.name.hasError = true;
        }
        if (!this.form.birth.flag) {
          this.form.birth.hasError = true;
        }
        if (!this.form.gender.flag) {
          this.form.gender.hasError = true;
        }
        if (!this.form.phone.flag) {
          this.form.phone.hasError = true;
        }
        if (!this.form.isCheckedAgreement.flag) {
          this.form.isCheckedAgreement.hasError = true;
        }
        return false;
      }
      return true;
    },
    async next() {
      if (this.checkFlag()) {
        const referParam = {
          rcvDrId: this.form.rcvDrId.value,
          sndDrId: this.form.userData.drId,
          sndDrNm: this.form.userData.drNm,
          sndHospNm: this.form.userData.sndHospNm,
          rcvHospNm: this.form.receive.value,
          rcvDrNm: this.form.doctor.value,
          diagCd: this.form.code.value,
          rcvParts: this.form.subject.value,
          patNm: this.form.name.value,
          patBirth: this.form.birth.value,
          patSex: this.form.gender.value === "female" ? 0 : 1,
          patTelno: this.form.phone.value,
          diagNm: this.form.diagnosis.value,
          referConts: this.form.vital.value,
          agreeId: this.form.agreeId.value,
          referId: this.form.referId.value,
          isDeletedAttchId: 0,
        };
        //console.log("referParam", referParam);
        const transedReferParam = JSON.stringify(referParam);
        const transData = new FormData();
        transData.append("referParam", transedReferParam);
        if (
          this.form.attachment.source !== "" &&
          this.form.attachment.source !== null
        ) {
          transData.append("referFile", this.form.attachment.source);
        }
        try {
          await this.REFER_REPLY(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "진료의뢰서 등록완료";
            this.popupSet.content = "진료의뢰서를 성공적으로 등록하였습니다.";
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/mypage/request/0";
            this.popupSet.destination = true;
          });
        } catch (error) {
          console.log("error: ", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 진료의뢰서 등록을 실패하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      } else {
        console.log("체크 상태확인");
      }
    },
  },
  watch: {},
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.viewer {
  position: relative;
  max-width: 645px;
  &.noneReceive {
    &:after {
      content: "클릭하여 수신병원을 선택해주세요.";
      color: #b7b7b7;
    }
  }
}
</style>
